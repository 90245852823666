import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import './config/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
// import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import OneSignalVue from 'onesignal-vue'
import { mounted } from 'vue-photo-grid/components/PhotoGridComponent.vue'

Vue.use(OneSignalVue, {
  appId: 'd8232fdf-f28d-4bc8-90df-167430fb8b2a'
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDcH70GTe-9uRhq-i8B7xWoybbPf8o-4OE',
    libraries: 'places'
  }
})

global.Raphael = Raphael

const mixin = {
  data () {
    return {
      permissions_test: []
    }
  },
  computed: {
    ...mapGetters(['loaderState', 'appInfo', 'authUserPermissionInfo', 'hotelTransactions', 'transInvoice', 'clientData', 'appTransactions', 'clientTransactions', 'mainsliders', 'appTransations', 'apps', 'invoices', 'invoice', 'permissionsUser', 'hotelReservations', 'accountants', 'sliders', 'slider', 'offers', 'offer', 'governorates', 'cities', 'services', 'features', 'landmarks', 'question', 'contacts', 'permissions', 'roles', 'role', 'users', 'hotels', 'hotel', 'userData', 'rooms', 'room', 'clients', 'transactionHistory', 'currency']),
    userInfo () {
      return JSON.parse(localStorage.getItem('booking-admin-info')).userAuth || false
    }
  },
    methods: {
    canAccess (permissions) {
      return permissions.some(item => JSON.parse(localStorage.getItem('permissions')).includes(item))
    },
    ...mapMutations(['SET_HOTEL']),
    ...mapActions([
      'loginUser',
      'getApp',
      'addHotelInvoice',
      'authUserPermissions',
      'getInvoiceList',
      'loginOut',
      'userPermissions',
      'getHotels',
      'getHotelTransactionList',
      'getClientTransactionList',
      'getAppTransactionList',
      'getHotelInvoices',
      'getHotelInvoice',
      'createHotelInvoice',
      'getHotel',
      'addHotels',
      'updateHotels',
      'removeHotels',
      'activeHotel',
      'updateMainSlider',
      'addMainSlider',
      'showingHotel',
      'getMainSliders',
      'PAID_RESERVATIONS',

      'getApps',
      'addApp',
      'updateApp',
      'addAamount',
      'addActive',
      'addPorduction',

      'getAppTransations',

      'getGovernorates',
      'addGovernorates',
      'updateGovernorates',
      'removeGovernorates',

      'getSliders',
      'showSlider',
      'addSliders',
      'addSliderBody',
      'updateSliders',
      'removeSliders',

      'getCities',
      'addCities',
      'updateCities',
      'removeCities',
      'getClient',
      'getServices',
      'addServices',
      'updateServices',
      'removeServices',

      'getAccountants',
      'getHotelReservationsById',
      'addMainSliders',
      'updateMainSliders',

      'getFeatures',
      'addFeatures',
      'updateFeatures',
      'removeFeatures',

      'getLandmarks',
      'addLandmarks',
      'updateLandmarks',
      'removeLandmarks',

      'getQuestion',
      'addQuestion',
      'updateQuestion',
      'removeQuestion',

      'getContacts',
      'sendAnswer',
      'removeContacts',

      'getFloors',

      'getPermissions',
      'addPermissions',
      'updatePermissions',
      'removePermissions',
      'assignPermissionsToUsers',

      'getRoles',
      'showRole',
      'addRoles',
      'updateRoles',
      'removeRoles',

      'getUsers',
      'getHotelUsers',
      'addUsers',
      'updateUsers',
      'removeUsers',

      'getRooms',
      'getRoom',
      'addRooms',
      'updateRooms',
      'removeRooms',

      'getOffers',
      'getOffer',
      'addOffers',
      'updateOffers',
      'removeOffers',

      'getClients',
      'addClients',
      'updateClients',
      'removeClients',
      'addWallet',

      'getCurrency',
      'addCurrency',
      'updateCurrency',
      'removeCurrency'
    ]),
    confermMsg (action) {
      Swal.fire({
        title: i18n.t('delete-title'),
        text: i18n.t('delete-hint'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: i18n.t('close'),
        confirmButtonText: i18n.t('confirm')
      }).then((result) => {
        if (result.isConfirmed) {
          action()
        }
      })
    },
    resetObject (target) {
      for (var key in target) {
        if (typeof target[key] === 'number') {
          target[key] = 0
        } else if (typeof target[key] === 'string') {
          target[key] = ''
        } else if (typeof target[key] === 'boolean') {
          target[key] = false
        } else if (Array.isArray(target[key])) {
          target[key] = []
        } else {
          target[key] = null
        }
      }

      return target
    },
    returnData (obg) {
      var data = new FormData()

      for (var key in obg) {
        if (obg[key] !== '' && obg[key] !== null) {
          data.append(key, obg[key])
        }
      }

      return data
    }
  }
}
Vue.mixin(mixin)
Vue.use(AlgoliaComponents)

Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate () {
    // Example prompting for notification permission
    this.$OneSignal.User.PushSubscription.optIn()
  }
}).$mount('#app')

window.vm = vm
