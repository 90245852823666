import $axios from '@/plugins/axios'
const model = 'hotel-invoice'
export default {
  state: {
    invoices: [],
    invoice: []
  },
  getters: {
    invoices: state => state.invoices,
    invoice: state => state.invoice,
  },
  actions: {
    async getHotelInvoices ({ commit }, filterData = { page: 1, hotel_id: '', invoice_no: ''}) {
      const response = await $axios.get(`/${model}/${filterData.hotel_id}?include=hotel,createdBy,updatedBy&sort=-invoice_no&take=10&page=${filterData.page}`)
      commit('GET_INVOICE', response.data)
    },
    async getHotelInvoice ({ commit }, id) {
      const response = await $axios.get(`/invoice/${id}`)
      commit('SHOW_INVOCIE', response.data)
    },
    async createHotelInvoice ({ commit }, id) {
      const response = await $axios.post(`/${model}/${id}`)
      commit('SET_INVOCIE', response.data)
    }
  },
  mutations: {
    GET_INVOICE (state, data) {
      state.invoices = data
    },
    SET_INVOCIE (state, data) {
      state.invoice = data
    },
    SHOW_INVOCIE (state, data) {
      state.invoice = data
    }
  }
}
