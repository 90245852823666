<template>
    <div id="features">
      <div class="container-fluid">
        <!-- Filters Section -->
        <div class="filters-section">
          <iq-card class="w-100">
            <template v-slot:body>
              <div class="filter-container" @change="filtering">
                <b-row>
                  <b-col cols="3">
                    <b-form-input type="text" placeholder="Invoice No" v-model="filterData['invoice_no']"
                      required></b-form-input>
                  </b-col>
                </b-row>
              </div>
            </template>
          </iq-card>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card>
            <template v-slot:cell(icon)="data">
              <i :class="data.item.icon"></i>
            </template>
            <template v-slot:headerTitle>
              <h4 class="card-title">Hotel Invoices</h4>
            </template>
            <template v-slot:headerAction>
            </template>
            <template v-slot:body>
              <b-table responsive :items="invoices.data" :fields="fields" class="mb-0 table-borderless">
                <template v-slot:cell(created_by)="data">
                    {{ data.item.created_by?data.item.created_by.name:null }}
                </template>
              <template v-slot:cell(actions)="data">
                <div class="actions-list d-flex align-items-center">
                  <b-button  v-b-tooltip.top="$t('edit')" variant=" iq-bg-success"
                    @click="getInvoice(data.item)" size="md"><i class="ri-file-text-fill p-0"></i></b-button>
                </div>
              </template>
              </b-table>
            </template>
          </iq-card>
        </div>
        <!-- Pagination Section -->
        <div class="pagination">
          <iq-card class="w-100 d-flex justify-content-center">
            <template v-slot:body>
              <b-pagination v-model="filterData.page" :total-rows="invoices.total"
                :per-page="invoices.per_page"></b-pagination>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
  <script>
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas'
  export default {
    name: 'Hotel Reservation',
    data() {
      return {
        filterData: {
          page: 1,
          invoice_no: '',
          hotel_id: this.$route.params.id,
        },
        payload: {
          kr_name: '',
          feature_id: ''
        },
        imageLoaded: false,
        total_price: 0,
        showFeatures: {},
        items: [],
        reservationList: [],
        icons: [
          {
            icon: 'fas fa-utensils',
            title: 'Food'
          },
          {
            icon: 'fas fa-glass-martini-alt',
            title: 'Bar'
          },
          {
            icon: 'fas fa-dumbbell',
            title: 'Gym'
          },
          {
            icon: 'fas fa-swimmer',
            title: 'Pool'
          },
          {
            icon: 'fas fa-hot-tub',
            title: 'Sauna'
          },
          {
            icon: 'fas fa-concierge-bell',
            title: 'Room Service'
          },
          {
            icon: 'fas fa-gamepad',
            title: 'Game Room'
          },
          {
            icon: 'fas fa-parking',
            title: 'Parking'
          }
        ]
      }
    },
    computed: {
      fields() {
        return [
          {
            label: this.$t('invoice-no'),
            key: 'invoice_no'
          },
          {
            label: this.$t('created_by'),
            key: 'created_by'
          },
          {
            label: this.$t('created-date'),
            key: 'created_at'
          },
          {
            label: this.$t('actions'),
            key: 'actions'
          }
        ]
      }
    },
    methods: {
      imageUrl() {
        return this.hotel.image;
      },
      filtering() {
        this.filterData.page = 1
        this.getHotelInvoices(this.filterData, this.$route.params.id)
      },
      closeModal(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.payload = this.resetObject(this.payload)
      },
      getInvoice(invoice){
        this.$router.push(`/invoice/${invoice.id}`)
      }
    },
    watch: {
      'filterData.page': {
        handler: function (value) {
          this.getHotelInvoices(this.filterData, this.$route.params.id)
        },
        deep: true
      }
    },
    mounted() {
      this.authUserPermissions();
      this.getHotelInvoices(this.filterData)
    }
  }
  </script>
  <style scoped>
  .tb-reservations {
    margin-left: 15px;
  }
  
  .tb-container {
    margin: 0 30px 0 30px;
    width: 90%;
  }
  
  .content {
    width: 100%;
    font-size: 14px;
    text-align: right;
    line-height: 5px;
  }
  
  .company-info {
    margin-top: 10px;
  }
  
  .line {
    width: 100%;
    height: 5px;
    background-color: #013b7d;
    margin: 20px 0;
  }
  
  .line-header {
    width: 100%;
    height: 1px;
    background-color: #013b7d;
    margin: 0 0 20px 0;
  }
  
  .header {
    margin-bottom: 30px;
  }
  
  .border-left-color {
    border-right: 1px solid #fff;
  }
  
  table,
  td,
  th {
    border: 1px solid;
    color: black;
  }
  
  th {
    text-align: center;
  }
  
  td {
    padding-left: 5px;
  }
  
  table {
    width: 1035px;
    border-collapse: collapse;
  }
  
  .total-price-tb {
    text-align: center;
  }
  
  .header-logo {
    height: 7rem;
  }
  
  .total-row {
    background-color: #f0f0f0;
  }
  
  .bg-price {
    background-color: #013b7d;
    color: #fff;
    border: 1px solid #013b7d;
  }
  
  .hidden-content {
    display: none;
  }
  </style>
  