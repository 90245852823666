import $axios from '@/plugins/axios'
import i18n from '@/i18n'
const model = 'homeSlider'
export default {
  state: {
    mainsliders: []
  },
  getters: {
    mainsliders: state => state.mainsliders
  },
  actions: {
    async getMainSliders ({ commit }, filterData = {
      take: 10,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: ''
    }) {
      const response = await $axios.get(`/${model}?take=${filterData.take}&page=${filterData.page}&filter[${i18n.locale}_name]=${filterData[`${i18n.locale}_name`]}`)
      commit('SET_MAINSLIDER', response.data)
    },
    async addMainSliders ({ commit }, payload) {
      console.log(payload)
      await $axios.post('/homeSlider', payload)
    },
    async updateMainSliders ({ commit }, payload) {
      const response = $axios.put(`/${model}/${payload.id}`, payload)
      commit('STORE_MAINSLIDER', response.data.data.id)
    },
    async removeMainSliders ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_MAINSLIDER', response.data.data.id)
    }
  },
  mutations: {
    SET_MAINSLIDER (state, data) {
      state.mainsliders = data
    },
    REMOVE_MAINSLIDER (state, id) {
      state.mainsliders.data = state.mainsliders.data.filter(x => x.id !== id)
    },
    STORE_MAINSLIDER (state, data) {
      state.mainsliders = data
    }
  }
}
