import $axios from '@/plugins/axios'
export default {
  state: {
    userData: {},
    authUserPermissionInfo: {},
  },
  getters: {
    userData: state => state.userData,
    authUserPermissionInfo: state => state.authUserPermissionInfo,
  },
  actions: {
    async loginUser ({ commit }, payload) {
      const response = await $axios.post('/auth/login', payload)
      commit('SORT_USERDATA', response.data)
    },
    async loginOut ({ commit }, payload) {
      const response = await $axios.post('/auth/logout', payload)
      commit('REMOVE_USERDATA', response.data)
    },
    async authUserPermissions ({ commit }) {
      const response = await $axios.get(`/premissions/user/${JSON.parse(localStorage.getItem('booking-admin-info')).userAuth.id}`)
      commit('USER_PERMISSIONS', response.data)
    },
    async updateAuth ({ commit, state }, payload) {
      return await $axios.post('/auth/update-profile', {
        ...state.userData,
        ...payload
      })
    }
  },
  mutations: {
    SORT_USERDATA (state, data) {
      state.userData = data
      localStorage.setItem('permissions', JSON.stringify(data.permissions))
      localStorage.setItem('booking-admin-info', JSON.stringify(data))
    },
    REMOVE_USERDATA (state, data) {
      return { state, data }
    },
    USER_PERMISSIONS (state, data) {
      localStorage.setItem('permissions', JSON.stringify(data.data))
      state.authUserPermissionInfo = data
    }
  }
}
