import $axios from '@/plugins/axios'

const model = 'reservations'
export default {
  state: {
    hotelTransactions: [],
    transInvoice: [],
    clientTransactions: [],
    appTransactions: [],
    reservations: [],
    reservation: {}
  },
  getters: {
    transInvoice: state => state.transInvoice,
    hotelTransactions: state => state.hotelTransactions,
    appTransactions: state => state.appTransactions,
    clientTransactions: state => state.clientTransactions,
    reservstionsList: state => state.reservations,
    reservation: state => state.reservation
  },
  actions: {
    async getHotelTransactionList ({ commit }, filterData = {
      take: 10,
      page: 0
    }) {
      const response = await $axios.get(`/hotel-transaction?include=reserve,creator&take=${filterData.take}&page=${filterData.page}&model_id=${filterData.model_id}&model_type=${filterData.model_type}&filter[trans_no]=${filterData.trans_no}&filter[ref]=${filterData.ref}`)
      commit('SET_HOTEL_TRANSACTIONS', response.data)
    },
    async getClientTransactionList ({ commit }, filterData = {
      take: 10,
      page: 0
    }) {
      const response = await $axios.get(`/client-transaction?include=reserve,creator&take=${filterData.take}&page=${filterData.page}&model_id=${filterData.model_id}&model_type=${filterData.model_type}&filter[trans_no]=${filterData.trans_no}&filter[ref]=${filterData.ref}`)
      commit('SET_CLIENT_TRANSACTIONS', response.data)
    },
    async getAppTransactionList ({ commit }, filterData = {
      take: 10,
      page: 0
    }) {
      const response = await $axios.get(`/app-transaction?include=reserve,creator&take=${filterData.take}&page=${filterData.page}&model_id=${filterData.model_id}&model_type=${filterData.model_type}&filter[trans_no]=${filterData.trans_no}&filter[ref]=${filterData.ref}`)
      commit('SET_APP_TRANSACTIONS', response.data)
    },
    async getAppTransactionList ({ commit }, filterData = {
      take: 10,
      page: 0
    }) {
      const response = await $axios.get(`/app-transaction?include=reserve,creator&take=${filterData.take}&page=${filterData.page}&model_id=${filterData.model_id}&model_type=${filterData.model_type}&filter[trans_no]=${filterData.trans_no}&filter[ref]=${filterData.ref}`)
      commit('SET_APP_TRANSACTIONS', response.data)
    },
    async addHotelInvoice ({ commit }, payload) {
      await $axios.post(`/transaction-invoice`, payload)
    },
    async getInvoiceList ({ commit }, id) {
      const response = await $axios.get(`/transaction-invoice/${id}`)
      commit('SET_INVOICE', response.data)
    },
    async addReservations ({ commit }, payload) {
      return await $axios.post(`/${model}`, payload)
    },

    async downloadReservation ({ commit }, payload) {
      const response = await $axios.get(`/download/reserve/${payload.id}`, {
        responseType: 'blob'
      })
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      // Create a temporary anchor element
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)

      // Set the download attribute with the desired file name
      downloadLink.setAttribute('download', payload.reservation_no + '.pdf')

      // Append the anchor element to the document body
      document.body.appendChild(downloadLink)

      // Trigger a click event to prompt the user to save the file
      downloadLink.click()

      // Remove the anchor element from the document
      document.body.removeChild(downloadLink)
      commit('UPDATE_RESERVATIONS', response.data.data)
    },
    async paidReservation ({ commit }, id) {
      const response = await $axios.put(`/paid-reservation/${id}`)
      commit('PAID_RESERVATIONS', response.data.data)
    }
  },
  mutations: {
    SET_HOTEL_TRANSACTIONS (state, data) {
      state.hotelTransactions = data
    },
    SET_APP_TRANSACTIONS (state, data) {
      state.appTransactions = data
    },
    SET_CLIENT_TRANSACTIONS (state, data) {
      state.clientTransactions = data
    },
    SET_INVOICE (state, data) {
      state.transInvoice = data
    },

  }
}
