import $axios from '@/plugins/axios'
const model = 'admin-client'
export default {
  state: {
    clients: [],
    clientData: [],
    transactionHistory: []
  },
  getters: {
    clientData: state => state.clientData,
    clients: state => state.clients,
    transactionHistory: state => state.transactionHistory
  },
  actions: {
    async getClients ({ commit }, filterData = { page: 1, name: '' }) {
      const response = await $axios.get(`/${model}?include=createdBy,updatedBy&take=10&page=${filterData.page}&filter[name]=${filterData.name}`)
      commit('SET_CLIENTS', response.data)
    },
    async getClient ({ commit }, id) {
      const response = await $axios.get(`/${model}/${id}`)
      commit('SET_CLIENT', response.data)
    },
    async addClients ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
      // commit('SORT_GOVERNORATES', response.data.data)
    },
    async updateClients ({ commit }, payload) {
      await $axios.put(`/${model}/${payload.id}`, payload)
      // commit('UPDATE_GOVERNORATES', response.data.data)
    },
    async addWallet ({ commit }, payload) {
      await $axios.post(`/add-wallet/client/${payload.id}`, payload.data)
      // commit('UPDATE_GOVERNORATES', response.data.data)
    },
    async removeClients ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_CLIENTS', response.data.data.id)
    }
  },
  mutations: {
    SET_CLIENTS (state, data) {
      state.clients = data
    },
    SET_CLIENT (state, data) {
      state.clientData = data
    },
    SORT_CLIENTS (state, data) {
      state.clients.data.push(data)
    },
    UPDATE_CLIENTS (state, data) {
      state.clients.data = state.clients.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_CLIENTS (state, id) {
      state.clients.data = state.clients.data.filter(x => x.id !== id)
    }
  }
}
