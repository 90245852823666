import $axios from '@/plugins/axios'
import i18n from '@/i18n'
const model = 'app'
export default {
  state: {
    apps: [],
    appInfo: []
  },
  getters: {
    apps: state => state.apps,
    appInfo: state => state.appInfo
  },
  actions: {
    async getApps ({ commit }, filterData = {
      take: 10,
      page: 1
    }) {
      const response = await $axios.get(`/${model}?include=createdBy,updatedBy&take=${filterData.take}&page=${filterData.page}`)
      commit('SET_APPS', response.data)
    },
    async getApp ({ commit }, id) {
      const response = await $axios.get(`/${model}/${id}?include=createdBy,updatedBy&take=${filterData.take}&page=${filterData.page}`)
      commit('SET_APP', response.data)
    },
    async addApp ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
      commit('SORT_APP', response.data.data)
    },
    async addAamount ({ commit }, payload) {
      await $axios.post(`/add-amount/${payload.app_id}`, payload)
    //   commit('SORT_APP', response.data.data)
    },
    async addActive ({ commit }, appId) {
      await $axios.patch(`/active-or-deactive-app/${appId}`)
    },
    async addPorduction ({ commit }, appId) {
      await $axios.patch(`/make-app-production/${appId}`)
    },
    async updateApp ({ commit }, payload) {
      await $axios.put(`/${model}/${payload.referance_id}`, payload)
      commit('UPDATE_APP', response.data.data)
    }
  },
  mutations: {
    SET_APPS (state, data) {
      state.apps = data
    },
    SET_APP (state, data) {
      state.appInfo = data
    },
    SORT_APP (state, data) {
      state.apps.data.push(data)
    },
    UPDATE_APP (state, data) {
      state.apps.data = state.apps.data.map(item => item.id === data.id ? data : item)
    }
  }
}
