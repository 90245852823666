import $axios from '@/plugins/axios'
import i18n from '@/i18n'
const model = 'app-transactions'
export default {
  state: {
    appTransations: []
  },
  getters: {
    appTransations: state => state.appTransations
  },
  actions: {
    async getAppTransations ({ commit }, filterData = {
      take: 10,
      page: 1,
    }) {
      const response = await $axios.get(`/${model}/${filterData.appId}?include=reserve&take=${filterData.take}&page=${filterData.page}`)
      commit('SET_APPS', response.data)
    }
  },
  mutations: {
    SET_APPS (state, data) {
      state.appTransations = data
    }
  }
}
