import axios from 'axios'
import store from '@/store/index'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import router from '@/router'
axios.interceptors.request.use(config => {
  config.baseURL = 'https://api.bookingiraq.com/api'
  // config.baseURL = 'http://127.0.0.1:8000/api'
  config.headers = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Lang: 'ar',
    Authorization: `Bearer ${localStorage.getItem('booking-admin-info') ? JSON.parse(localStorage.getItem('booking-admin-info')).token : false}`
  }
  store.commit('changeLoaderStatus', true)
  return config
})

axios.interceptors.response.use((response) => {
  if (response.config.method !== 'get' && !response.request.responseURL.includes('auth/update-profile')) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: response.data.message,
      showConfirmButton: false,
      timer: 1500
    })
  }
  store.commit('changeLoaderStatus', false)
  return response
}, (error) => {
  store.commit('changeLoaderStatus', false)
  if (error.response.status === 400) {
    var errorMsg = ''
    if (error.response.data.data === undefined) {
      for (var key in error.response.data) {
        errorMsg += `-${error.response.data[key][0]}`
      }
    } else {
      errorMsg = error.response.data.message
    }
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMsg,
      showConfirmButton: false,
      timer: 5000
    })
  } else if (error.response.status === 401) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.response.data.message,
      showConfirmButton: false,
      timer: 5000
    })
    localStorage.removeItem('booking-admin-info')
    router.push('/login')
  }
  store.commit('changeLoaderStatus', false)
  return Promise.reject(error)
}
)

export default axios
